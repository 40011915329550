<template>
    <div class="download_container">
        <el-row>
          <el-col :span="18" :offset="3">
            <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(3)">技术服务</span> &gt; <span @click="changeNav(3)">产品适配</span> &gt; <span>兼容列表</span></p>
          </el-col>
          <el-col :span="18" :offset="3">
            <p class="desc_text">兼容列表提供磐石产品适配清单，包括操作系统、外设等。</p>
          </el-col>
          <el-col :span="18" :offset="3">
            <div class="title">
                <p>检索</p>
            </div>
          </el-col>
          <el-col :span="10" :offset="7">
            <!--    搜索框-->
            <div class="searchBox">
                <input type="text" v-model="searchInput" class="searchInput">
                <div class="searchIcon iconfont icon-sousuo1" @click="getDatas">搜索</div>
            </div>
          </el-col>
          <el-col :span="18" :offset="3">
            <div class="title">
              <p>兼容列表</p>
            </div>
          </el-col>
        </el-row>
        <!--    文档列表-->
        <el-row>
          <el-col :span="18" :offset="3">

            <div class="card" v-for="(item,index) in showDatas" :key="index" v-show="item.datas.length > 0 || Object.keys(item.datas).length != 0">
              <p class="head">{{item.typeName}}</p>
              <!-- 无二级分类 -->
              <div v-if="item.tag == 0">
                <div class="listUl">
                  <p v-for="(itema,index) in item.datas" :key="'a' + index">
                      <i :class="itema.icon"></i>
                    <span href="#">{{itema.name}}</span>
                  </p>
                  <div class="clearfix"></div>
                </div>
              </div>
              <!-- 有二级分类 -->
              <div v-else>
                <div v-for="(itemb,index) in item.datas" :key="'b' + index">
                  <h6 class="hrtit">{{itemb[0].preName}}</h6>
                  <div class="listUl">
                    <p v-for="itemc in itemb" :key="itemc.id">
                      <i :class="itemc.icon"></i>
                      <span href="#">{{itemc.name}}</span>
                    </p>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>

          </el-col>
        </el-row>


    </div>
</template>

<script>
import {searchSpShowList,getspTypeList} from '@/api'
// import _ from 'lodash'
import axios from 'axios'
export default {
    name: "jrlb",
    data() {
      return {
        // 搜索框
        searchInput: '',
        // 验证框显示
        verifyVisible:false,
        // 
        verify_num:'',
        fileName:'',
        fileId:'',
        // 展示数据
        osList:[],
        equips:{},
        invented:[],
        // 
        searchName:'1',
        // 展示设备列表
        showDatas:[]
      }
    },
    created() {
      this.getDatas()
      // this.getPsDoc()
      // this.getspTypeList()
    },
    methods: {
      // 打开验证码弹窗
      showVerify(fileName,id){
        this.verifyVisible = true
        this.fileName = fileName
        this.fileId = id
      },
      changeNav(navNum){
        this.$store.dispatch('changeShow',navNum)
      },
      reactive_verify(){
        this.verify_num = ''
        this.$refs.Verify.handleDraw()
      },
      // 获取文档分类
      async getspTypeList(){
        let result = await getspTypeList();
        console.log('分类：',result)
        for (const key in result.data) {
          let temp = {name:result.data[key].typeName,datas:[]}
          this.showDatas.push(temp)
        }
      },
      /* 获取、整理兼容列表数据 */
      getDatas(){
        let that = this
        this.showDatas = []
        axios.get('/api/psProductFit/listTypeName').then(({data}) => {
          // 整理分类数据
          for (const key in data.data) {
            let temp = {typeName:data.data[key].typeName,datas:[],tag:0}
            that.showDatas.push(temp)
          }
          }).then( async () => {
            let groups = {}
            // 整理详细数据
            let res = await searchSpShowList(this.searchInput)
            let dataTemp = []
            // 整理一级目录是否有二级分类
            res.data.filter((item) => {
              if(item.name != null){
                if(item.preName){
                  // 不存在二级分类
                  dataTemp.push(item)
                }else{
                  // 存在二级分类遍历分类并push
                  that.showDatas.filter((items) => {
                    if(item.typeName == items.typeName){
                      items.datas.push(item)
                    }
                  })
                }
              }
              
            })
            // 通过分类进行区分
            dataTemp.forEach( function( o ) {
              let group = o['preName']
              groups[group] = groups[group] || []
              groups[group].push( o )
            });
            // 二级分类与一级分类合并
            for (const key in groups) {
              for (const keya in that.showDatas) {
                if(groups[key][0].typeName == that.showDatas[keya].typeName){
                  // console.log(groups[key])
                  that.showDatas[keya].datas.push(groups[key])
                  that.showDatas[keya].tag = 1
                }
              }
            }
          }).catch(error => {
            console.log(error)
          })
      },
      search(){
        this.getPsDoc()
      },
    },
    
}
</script>

<style scoped>
/* 通用样式*/
li {
    list-style: none;
}

a {
    color: #496AB1;
    text-decoration: none;
}
/* 验证码 */
.verify_inp{
  float: left;
  line-height: 38px;
  border: 1px solid #409EFF;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
  padding-left: 10px;
}
.verify_show{
  float: left;
  margin-right: 10px;
}
.verify_btn{
  float: left;
}
.clearfix{
  clear: both;
}
/* 导航栏 */
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
/*页面样式*/
.download_container{
  padding-bottom: 50px;
}
/* 标题 */
.desc_text{
  font-size: 16px;
  color: #666666;
  margin-top: 30px;
  margin-bottom: 40px;
}
.title{
  margin-top: 30px;
  margin-bottom: 30px;
}
.title p{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;

}
/*搜索框*/
.searchBox {
    width: 100%;
    text-align: right;
    margin: 1% 0 3% 0;
    position: relative;
}

.searchInput {
    padding: 0;
    width: 96%;
    border: 1px solid #cacaca;
    border-radius: 0px;
    line-height: 46px;
    padding-left: 10px;
}

.searchIcon {
  padding: 0;
  cursor: pointer;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  width: 16%;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background: #e40f0f;
}
.searchIcon::before{
  margin-right: 10px;
}
.clearfix{clear: both;}

/*文档列表*/
.card{
  margin-bottom: 30px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  overflow: hidden;
}
.head{
  background: #f2f2f2;
  padding: 10px 20px;
  font-size: 22px;
  font-weight: 500;
}
.listTitle {
  line-height: 48px;
  font-size: 32px;
  color: #101010;
}
.listUl{
  padding: 10px 50px;
}
.clearfix{clear: both;}
.listUl p {
  float: left;
  width: 25%;
  font-size: 18px;
  color: #496AB1;
  margin: 0.5% 0;
  height: 52px;
}
.listUlp {
  float: left;
  padding-left: 50px;
  padding-top: 10px;
  width: 25%;
  font-size: 18px;
  color: #496AB1;
  margin: 0.5% 0;
  cursor: pointer;
  height: 52px;
}
.listUl p i{
  display: block;
  margin-right: 5px;
  position: relative;
  top: 3px;
  float: left;
}
.listUl p span{
  font-size: 16px;
  line-height: 26px;
  float: left;
  width: calc(100% - 25px);
}

/* 抽屉文档列表样式 */
.listUld{
  width: 80%;
  margin:0 auto;

}
.listUld p {
  font-size: 18px;
  line-height: 30px;
  color: #496AB1;
  margin: 5px 0;
  cursor: pointer;
}
.listUld p img{
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.listUld p a{
  font-size: 14px;
  line-height: 40px;
}
.hrtit{
  font-size: 18px;
  color: #000;
      margin-left: 20px;
    margin-top: 10px;
}
</style>